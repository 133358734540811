







import { Component } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  name: 'dashboard-common-dialog-close-header',
})
export default class CloseHeader extends Vue {}
