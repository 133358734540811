






















import { Component, Prop, Watch } from 'vue-property-decorator'
import Vue from 'vue'

import { dateFormat } from '~/filters'

@Component({
  name: 'dashboard-common-widget-date-control',
})
export default class DateControl extends Vue {
  @Prop()
  public value: Date
  @Prop({
    default: 'DD MMM YYYY',
  })
  public format: string
  @Prop({
    type: Function,
  })
  public allowedDates: (date: string) => boolean
  @Prop({
    default: 'date',
  })
  public type: string

  public menu: boolean = false
  get formattedDate() {
    return dateFormat(this.value, this.format)
  }

  @Watch('menu')
  public onMenuChange(to: boolean) {
    // When menu close
    if (!to) {
      this.$emit('close')
    }
  }
}
