









import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  name: 'common-preload',
})
export default class Preload extends Vue {
  @Prop({ default: true })
  public displayLoading: boolean
  @Prop({ default: 70 })
  public size: number
}
