





import { Component } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  name: 'dashboard-common-mobile-wrapper',
})
export default class MobileWrapper extends Vue {}
