
















import { Component, Watch } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  name: 'common-snackbar',
})
export default class Snackbar extends Vue {
  public show: boolean = false
  public message: string = ''
  public timeout: number = 2000

  get snackbarPayload() {
    return this.$store.myGetters.getSnackbar
  }

  get color() {
    return (this.snackbarPayload && this.snackbarPayload.color) || undefined
  }

  get showClose() {
    return this.snackbarPayload && this.snackbarPayload.showClose
  }

  public getPositionFlag(positionType: string) {
    return this.snackbarPayload && this.snackbarPayload.position
      ? this.snackbarPayload.position.indexOf(positionType) >= 0
      : false
  }

  @Watch('snackbarPayload')
  public onSnackbarPayloadChange() {
    if (this.snackbarPayload) {
      this.message = this.snackbarPayload.message
      this.timeout =
        this.snackbarPayload.timeout !== undefined ? this.snackbarPayload.timeout : 2000
      this.show = true
    } else {
      this.show = false
    }
  }

  @Watch('show')
  public onShowChange() {
    // when timeout
    if (this.show === false) {
      this.$store.myActions.setSnackPayload(undefined)
    }
  }
}
