





import { Component } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  name: 'dashboard-common-widget-close-button',
})
export default class CloseButton extends Vue {}
