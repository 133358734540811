










import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  name: 'common-text-clamp',
})
export default class TextClamp extends Vue {
  @Prop() public value: string
  @Prop({
    default: 2,
  })
  public lineClamp: number
  @Prop() public height: number
  @Prop() public disableTooltip: boolean

  get wrapperStyle() {
    return {
      '-webkit-line-clamp': this.lineClamp,
      height: this.height,
    }
  }
}
