








import { Component } from 'vue-property-decorator'
import Vue from 'vue'
@Component({
  name: 'dashboard-common-widget-shrink-widget-button',
})
export default class ShrinkWidgetButton extends Vue {}
