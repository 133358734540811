










import { Component, Prop } from 'vue-property-decorator'
import { setMonth } from 'date-fns'
import Vue from 'vue'

import { dateFormat } from '~/filters'

@Component({
  name: 'dashboard-collaborate-common-month-select',
})
export default class MonthSelect extends Vue {
  @Prop()
  public value: string

  get months() {
    return new Array(12).fill(0).map((i, index) => ({
      text: dateFormat(setMonth(new Date(), index), 'MMM'),
      value: index,
    }))
  }
}
