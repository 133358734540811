









import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  name: 'dashboard-collaborate-common-year-select',
})
export default class YearSelect extends Vue {
  @Prop()
  public value: string

  @Prop({
    required: true,
  })
  public startingYear: number

  @Prop({
    required: true,
  })
  public endingYear: number

  get years() {
    const yearArray = []

    let startingYear = this.startingYear

    while (startingYear <= this.endingYear) {
      yearArray.push(startingYear.toString())
      startingYear++
    }
    return yearArray
  }
}
