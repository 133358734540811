






import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  name: 'dashboard-widget-counter-display',
})
export default class CounterDisplay extends Vue {
  @Prop()
  public counter: number
  @Prop()
  public name: string
  @Prop()
  public selected: boolean

  get counterClasses() {
    const classes = []
    if (this.selected) {
      classes.push('dashboard-counter-display--selected')
    }
    return classes
  }
}
