








import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

import { dateFormat } from '~/filters'

@Component({
  name: 'dashboard-common-widget-date-button-display',
  filters: {
    dateFormat,
  },
})
export default class DateButtonDisplay extends Vue {
  @Prop() public date: Date
  @Prop() public selected: boolean
  @Prop() public block: boolean

  get buttonColor() {
    return this.selected ? '#4a90e2' : ''
  }
}
