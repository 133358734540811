




















import { Component, Prop } from 'vue-property-decorator'
import { GroupModel } from '@technology/accounts-js/dist/types/modules/group'
import Vue from 'vue'

@Component({
  name: 'dashboard-collaborate-common-input-dimmed-group-select',
})
export default class DimmedGroupSelect extends Vue {
  @Prop()
  public value: GroupModel[]
  @Prop()
  public groups: GroupModel[]
}
