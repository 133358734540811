import { render, staticRenderFns } from "./WidgetNameDisplay.vue?vue&type=template&id=d6dab740&scoped=true&lang=pug&"
import script from "./WidgetNameDisplay.vue?vue&type=script&lang=ts&"
export * from "./WidgetNameDisplay.vue?vue&type=script&lang=ts&"
import style0 from "./WidgetNameDisplay.vue?vue&type=style&index=0&id=d6dab740&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6dab740",
  null
  
)

export default component.exports