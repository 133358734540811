






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'common-rectangle-chip',
})
export default class RectangleChip extends Vue {
  @Prop()
  public value: string
}
