import { render, staticRenderFns } from "./ShrinkWidgetButton.vue?vue&type=template&id=51ebb30c&scoped=true&lang=pug&"
import script from "./ShrinkWidgetButton.vue?vue&type=script&lang=ts&"
export * from "./ShrinkWidgetButton.vue?vue&type=script&lang=ts&"
import style0 from "./ShrinkWidgetButton.vue?vue&type=style&index=0&id=51ebb30c&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51ebb30c",
  null
  
)

export default component.exports