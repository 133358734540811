










import { Component, Prop, Watch } from 'vue-property-decorator'
import { differenceInHours, differenceInMinutes, differenceInSeconds } from 'date-fns'
import Vue from 'vue'
@Component({
  name: 'dashboard-common-widget-refresh-button',
})
export default class RefreshButton extends Vue {
  @Prop()
  public date: Date
  @Prop()
  public loading: boolean

  public lastCurrentDate = new Date()
  public intervalId: NodeJS.Timer

  get statement() {
    const hourDiff = differenceInHours(this.lastCurrentDate, this.date)
    const minuteDiff = differenceInMinutes(this.lastCurrentDate, this.date)
    const secondDiff = differenceInSeconds(this.lastCurrentDate, this.date)

    if (hourDiff) {
      return `${hourDiff}h ago`
    } else if (minuteDiff) {
      return `${minuteDiff}m ago`
    } else {
      return `${secondDiff}s ago`
    }
  }

  // If the date is changed, lastCurrentDate need to be updated

  @Watch('date')
  public onDateChanged() {
    this.lastCurrentDate = new Date()
  }

  public created() {
    this.intervalId = setInterval(() => {
      this.lastCurrentDate = new Date()
    }, 1000)
  }

  public destroyed() {
    clearInterval(this.intervalId)
  }
}
