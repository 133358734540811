




import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
@Component({
  name: 'dashboard-widget-title-display',
})
export default class TitleDisplay extends Vue {
  @Prop()
  public name: string
}
