














import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
@Component({
  name: 'dashboard-common-widget-today-button',
})
export default class TodayButton extends Vue {
  @Prop() public btnClasses: string

  get btnClassesArray() {
    return this.btnClasses ? this.btnClasses.split(' ') : []
  }
}
